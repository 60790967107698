import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`And finally, the most important reason, iOS must remain a closed platform. Fortunately, Apple went on to back away from this argument a little bit. Now we can at least get games authored in Unity, Unreal, and the like. We just have to deal with the authoritarian approval process.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This becomes even worse if the third party is supplying a cross platform development tool. The third party may not adopt enhancements from one platform unless they are available on all of their supported platforms. Hence developers only have access to the lowest common denominator set of features. Again, we cannot accept an outcome where developers are blocked from using our innovations and enhancements because they are not available on our competitor’s platforms.`}</p>
    </blockquote>
    <p>{`The counter-argument here is history. I just want to give everyone a chance to read it again.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Perhaps Adobe should focus more on creating great HTML5 tools for the future, and less on criticizing Apple for leaving the past behind.`}</p>
    </blockquote>
    <p>{`The last sentence is the most painful one. Adobe was already creating HTML5 tools. As discussed, Adobe was also integral to getting the standards bodies back on track with the reforming of TC39, ES4, and E4X. And to sign off this way was clearly a call to arms for all the Apple fans. They were all given permission to see Flash as a dinosaur. The effects of this were astounding, by the way. Talk to anyone who was working in Flash in 2011 or later, they'll tell you how unwanted they were. In the worst cases, people removed Flash from their resumes because having it there could get you automatically filtered out of an applicant pool. Or an interview would oddly be about Flash and how it's dead now instead of about what the responsibilities of the job would actually be.`}</p>
    <p className="barely-loud">Reliving this history stings. It stings even more with experienced eyes I didn't have when I was 19. Tech trends come and go for squishy human reasons and under the heavy influence of capitalism.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      